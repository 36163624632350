
import { defineComponent } from 'vue';
import FormLockedPage, {IValidateData} from "@/components/FormLockedPage.vue"
import {useStore} from "vuex"
import {key} from "@/store"

export default defineComponent({
  name: 'log',

  components: {
    FormLockedPage
  },

  data() {
    return {
      store: useStore(key)
    }
  },

  methods: {
    loginToApp(e: IValidateData) {
      this.store.commit("changeAppConnection", e)
    }
  }
});
