
import { defineComponent, toRefs } from 'vue';
import {useStore} from "vuex"
import {key} from "@/store"

export default defineComponent({
  name: 'FormLockedPage',

  props: {
    logID: {
      required: false,
      type: String,
      default: "",
    },

    buttonText: {
      required: false,
      type: String,
      default: "",
    }
  },

  mounted() {},

  components: {
  },

  data() {
    return {
      store: useStore(key),
      showPassword: false,
      form_id: "",
      form_passsword: "",
    }
  },

  computed: {
    withFormId(): boolean {
      return this.logID.length === 0
    },
  },

  methods: {
    togglePasswordView(e: Event) {
      e.preventDefault()
      this.showPassword = !this.showPassword
    },

    validateForm(e: Event) {
      e.preventDefault()
      this.$emit("validate", {id: this.withFormId ? this.form_id : this.logID, password: this.form_passsword} as IValidateData)
    }
  }

});

export interface IValidateData {
  id:       string,
  password: string,
}

